<script setup>
import Checkbox from '@/Components/Checkbox.vue';
import GuestLayout from '@/Layouts/GuestLayout.vue';
import InputError from '@/Components/InputError.vue';
import InputLabel from '@/Components/InputLabel.vue';
import PrimaryButton from '@/Components/PrimaryButton.vue';
import TextInput from '@/Components/TextInput.vue';
import { Head, Link, useForm } from '@inertiajs/vue3';
import { useDark } from '@vueuse/core';

const isDark = useDark(false)

defineProps({
    canResetPassword: {
        type: Boolean,
    },
    status: {
        type: String,
    },
});

const form = useForm({
    input_type: '',
    password: '',
    remember: false,    
});

const submit = () => {
    localStorage.clear();
    sessionStorage.clear();
    form.post(route('login'), {
        onSuccess: () => {
            form.reset();
        },
    });
};
</script>

<template>
    <GuestLayout>
        <Head title="Connexion" />
        <div class="p-5 lg:w-5/6 xl:w-3/4 2xl:p-0 2xl:w-2/4 mx-auto relative">
            <div v-if="$page.props.todayis.isNewYear" class="lg:-mt-[150px]">
                <img v-if="isDark" src="https://cdn.fun.paris/imageMPP/bonne_annee_darkk_2025.png?height=300" class="mb-5 mx-auto" />
                <img v-else src="https://cdn.fun.paris/imageMPP/bonne_annee_2025.png?height=300" class="mb-5 mx-auto" />
            </div>
            <div v-if="$page.props.todayis.isChristmas" class="lg:absolute lg:-top-[45%]"><img src="https://cdn.fun.paris/imageMPP/joyeux_noel_2024.png"/></div>
            <!-- v-if="$page.props.todayis.isNewYear" <template v-if="$page.props.todayis.isChristmasEve">
                <img src="https://cdn.fun.paris/imageMPP/christmas_tree_decoration_2024.png?height=300" class="mx-auto hidden md:block md:-mt-30 md:mb-5 lg:-mt-38 lg:mb-5" />
            </template>-->
            <h1 class="text-2xl sm:text-4xl font-bold my-2">Connectez à votre Extranet</h1>
            <p>Demandez vos identifiants ou commandez par téléphone : 
                <a href="tel:+33179758000" class="font-medium" title="Ouvert 7j/7 de 7h30 à 23h">01 79 75 80 00</a>
            </p>
            <template v-if="$page.props.todayis.isChristmasEve || $page.props.todayis.isChristmas">
                <img src="https://cdn.fun.paris/imageMPP/border-christmas_2_2024.png" class="my-3"/>
            </template>
            <form @submit.prevent="submit" class="mt-7">
                <div v-if="status" class="mb-4 font-medium text-sm text-green-600">
                    {{ status }}
                </div>
                <div class="space-y-2">
                    <label class="font-semibold">Nom d'utilisateur / E-mail</label>
                    <input type="text" v-model="form.input_type" class="input w-full" placeholder="Adresse e-mail ou Username" autocomplete="username" autofocus>
                    <InputError class="mt-2" :message="form.errors.email" />
                    <InputError class="mt-2" :message="form.errors.username" />
                </div>

                <div class="mt-4 space-y-2">
                    <label class="font-semibold">Mot de passe</label>
                    <input type="password" v-model="form.password" class="input w-full" placeholder="Mot de passe" autocomplete="current-password">
                    <InputError class="mt-2" :message="form.errors.password" />
                </div>

                <div class="flex justify-between mt-4">
                    <label class="flex items-center">
                        <Checkbox name="remember" v-model:checked="form.remember" />
                        <span class="ml-2">Se souvenir de moi</span>
                    </label>
                    <Link
                        v-if="canResetPassword"
                        :href="route('password.request')"
                        class="underline text-sm text-primary-content font-semibold rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                        Mot de passe oublié ?
                    </Link>
                </div>

                <button class="rounded-md w-full px-3 py-2 text-lg font-medium mt-5" :class="{ 'opacity-50': form.processing },  $page.props.todayis.isChristmasEve || $page.props.todayis.isChristmas ? 'button-red-gradient' : 'button-green-gradient'" :disabled="form.processing">Se connecter</button>
            </form>
            
            <div class="my-8 relative">
                <div class="flex items-center inset-0 absolute">
                    <div class="border-t w-full border-gray-200 dark:border-primary-800"></div>
                </div>
                <div class="flex justify-center relative">
                    <div class="bg-white dark:bg-primary-950 px-1">OU</div>
                </div>
            </div>
            <a href="https://guide.paris" target="_blank" type="button" class="btn-primary w-full block text-center py-2 text-base font-medium tracking-wide rounded-lg">Commandez via votre site dédié</a>
        </div>
    </GuestLayout>
</template>
