<script setup>
import { ref } from 'vue'

const particlesOptions = ref({
  particles: {
    number: {
      value: 200, // number of snowflakes
      density: {
        enable: true,
        value_area: 800,
      },
    },
    color: {
      value: "#dcf7fc",
    },
    shape: {
      type: "circle", // snowflake shape
    },
    opacity: {
      value: 0.75,
      random: true,
      anim: {
        enable: true,
        speed: 5,
        opacity_min: 0.1,
      },
    },
    size: {
      value: 2, // snowflake size
      random: true,
      anim: {
        enable: true,
        speed: 5,
        size_min: 1,
      },
    },
    line_linked: {
      enable: false, // no lines between particles
    },
    move: {
      enable: true,
      speed: 1, // speed of the snowfall
      direction: "bottom",
      random: false,
      straight: false,
      out_mode: "out",
    },
  },
  interactivity: {
    events: {
      onhover: {
        enable: true,
        mode: "repulse", // snowflakes will move away when hovered
      },
    },
  },
});
</script>
<template>
    <vue-particles :id="'tsparticles'" :options="particlesOptions" />
</template>