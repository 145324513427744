<script setup>
import moment from 'moment';
import { computed, ref } from 'vue';
import { DotLottieVue } from '@lottiefiles/dotlottie-vue'

const today = moment();
const todayis = computed(() => {
    const month = today.month() + 1; // Moment.js months are 0-based
    const day = today.date();
    return {
        isChristmasEve: month === 12 && day >= 15 && day <= 23,
        isChristmas: month === 12 && day >= 24 && day <= 25,
        isNewYearEve: month === 12 && day >= 26 && day <= 31,
        isNewYear: month === 1 && day >= 1 && day <= 5,
    };
});

const loading = ref(false);

function start() {
    loading.value = true;
}

function finish() {
    loading.value = false;
}

defineExpose({ start, finish });
</script>
<template>
    <template v-if="loading"><!-- NOEL fixed top-0 left-0 w-full bg-gradient-to-r from-pink-500 to-indigo-500 text-white text-center p-2 z-40-->
        <div class="fixed top-0 left-0 w-full bg-gradient-to-r md:text-base text-white text-center p-3 z-40"
        :class="[
            todayis.isChristmasEve || todayis.isChristmas ? 'from-green-600 to-emerald-400' : 
            todayis.isNewYearEve || todayis.isNewYear ? 'from-indigo-500 to-red-400' : 
            'from-pink-500 to-indigo-500'
        ]">
            <p>Chargement... veuillez patienter</p>
        </div>
        
        <div class="fixed left-5 bottom-5 z-50">
            <template v-if="todayis.isChristmasEve || todayis.isChristmas">
                <DotLottieVue style="height: 300px; width: 300px" autoplay loop src="https://lottie.host/02b71f0a-5a3b-457f-9fc2-fb52cc2643b1/2meyYOpNZc.lottie" />
            </template>
            <template v-else-if="todayis.isNewYearEve || todayis.isNewYear">
                <DotLottieVue style="height: 500px; width: 500px" autoplay loop src="https://lottie.host/88d33534-24de-4639-83fc-9187c2fcdf1c/UMzKKgE4em.lottie" />
            </template>
            <svg v-else class="animate-bounce" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin:auto;background:transparent;display:block;" width="5rem" height="5rem" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                <circle cx="50" cy="50" r="30" stroke="current" stroke-width="10" class="stroke-indigo-600" fill="none"></circle>
                <circle cx="50" cy="50" r="30" stroke="current" stroke-width="8" class="stroke-pink-500" stroke-linecap="round" fill="none">
                <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;180 50 50;720 50 50" keyTimes="0;0.5;1"></animateTransform>
                <animate attributeName="stroke-dasharray" repeatCount="indefinite" dur="1s" values="18.84955592153876 169.64600329384882;94.2477796076938 94.24777960769377;18.84955592153876 169.64600329384882" keyTimes="0;0.5;1"></animate>
                </circle>
            </svg>
        </div>
    </template>
</template>
<style scoped>
.page-loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
</style>